import React from "react"
import styled from "styled-components"
import { gray } from "@vschool/lotus"
import vschoolLogo from "../../images/vschool-logo.png"
import QueryLink from "../shared/QueryLink.js"

const Nav = styled.nav`
  height: 72px;
  background-color: ${gray.lighter};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 16px rgba(33, 32, 31, 0.1);
  position: relative;
  z-index: 2;
`

const Logo = styled.img`
  height: 24px;
  width: 97px;
  margin-left: 18px;

  /* catering to iphone5 users apparently */
  @media (max-width: 380px) {
    width: 70px;
    height: 17px;
  }

  @media (min-width: 1200px) {
    margin-left: 88px;
  }
`

const Links = styled.div`
  margin-right: 32px;

  & > a:nth-child(2) {
    margin-left: 32px;
  }

  /* also catering to iphone5 here */
  @media (max-width: 380px) {
    & > a:nth-child(2) {
      margin-left: 8px;
    }
  }

  @media (min-width: 1200px) {
    margin-right: 88px;
  }
`

const NavLink = styled(QueryLink)`
  text-decoration: none;
  color: ${gray.darker};
  font-family: "aktiv-grotesk";
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;

  @media (max-width: 380px) {
    font-size: 12px;
  }

  :active {
    color: ${gray.darker};
  }

  :visited {
    color: ${gray.darker};
  }
`

export default function Navbar() {
  return (
    <Nav>
      <Logo src={vschoolLogo} />
      <Links>
        <NavLink
          to="/"
          activeStyle={{ borderBottom: `1px solid ${gray.dark}` }}
        >
          Home
        </NavLink>
        <NavLink
          to="/my-base-scholarship"
          activeStyle={{ borderBottom: `1px solid ${gray.dark}` }}
        >
          Scholarships
        </NavLink>
      </Links>
    </Nav>
  )
}
