export const data = {
  // header (Home Page)
  homeTitle: "Full Ride Scholarship For Veterans and Military Spouses",
  homeHeader: "Veteran Backed Tech Education",
  homeInfo:
    "Apply for a $6,000 scholarship to learn to code + UX/UI design with V School! Online full-time & part-time classes are customized to your busy schedule with weekly start dates so you can begin right away.",
  homeSubheader: "100% Online | GI Bill® Accepted | Pay later",
  homeBtnText: "Apply Now",
  homeBtnLink: "https://calendly.com/v-school/apply",
  homeHeroImg: require("../images/home-heroimg.png"),

  // header (My Base Page)
  pageTitle: "FULL RIDE SCHOLARSHIP FOR VETERANS AND MILITARY SPOUSES",
  myBaseLogo: require("../images/my-base-logo.png"),
  pageHeader: "Military & Veteran Scholarship",
  pageInfo:
    "Launch your dream career in tech online with V School. We take time out of the learning equation, making it so you move forward through the program as you master each skill level.",
  pageSubheader: "100% TUITION PAID | 100% ONLINE | 1:1 VETERAN MENTORSHIP",
  pageBtnText: "Apply Now",
  pageBtnLink: "https://calendly.com/v-school/apply",
  heroImg: require("../images/mybase-heroimg.png"),

  // landing a job (Home Page)
  homeLandingAJobHeader: "Why Veterans Choose V School",
  homeLandingAJobInfo:
    "Our immersive technical training is similar to the bootcamp-style training that our military personnel receive on entering the service, except V School trains veterans on the skills necessary to re-enter civilian life. We work with industry leaders around the world to keep curriculum up-to-date for the current and future needs of the tech industry.",
  homePhases: [
    {
      phaseNum: "PHASE 1",
      phaseHeader: "Skills Building",
      phaseInfo:
        "Learn to code with our full stack JavaScript web development and be job-ready in approximately 3-6 months.",
      id: "105",
    },
    {
      phaseNum: "PHASE 2",
      phaseHeader: "Experience Building",
      phaseInfo:
        "Work on real-life projects to gain experience working on a team and have live projects to add to your portfolio.",
      id: "106",
    },
    {
      phaseNum: "PHASE 3",
      phaseHeader: "Career Training",
      phaseInfo:
        "Work alongside our outcomes team to build resumes and portfolios, network, and land a meaningful job and a new career!",
      id: "107",
    },
  ],

  // landing a job (My base page)
  landingAJobHeader: "Landing a Job Matters Most",
  landingAJobInfo:
    "Our immersive technical training is similar to the bootcamp-style training that our military personnel receive on entering the service, except V School trains veterans on the skills necessary to re-enter civilian life. We work with industry leaders around the world to keep curriculum up-to-date for the current and future needs of the tech industry.",
  phases: [
    {
      phaseNum: "PHASE 1",
      phaseHeader: "Skills Building",
      phaseInfo:
        "Learn to code with our full stack JavaScript web development and be job-ready in approximately 3-6 months.",
      id: "100",
    },
    {
      phaseNum: "PHASE 2",
      phaseHeader: "Experience Building",
      phaseInfo:
        "Work on real-life projects to gain experience working on a team and have live projects to add to your portfolio.",
      id: "101",
    },
    {
      phaseNum: "PHASE 3",
      phaseHeader: "Career Training",
      phaseInfo:
        "Work alongside our outcomes team to build resumes and portfolios, network, and land a meaningful job and a new career!",
      id: "102",
    },
  ],

  ///////////////////////////////////////////////////////////
  // The rest of this data is shared between the two pages //
  ///////////////////////////////////////////////////////////

  // Testimonial 1
  testimonial1:
    "As a veteran, I find the short-format course is an ideal opportunity for veterans and spouses looking for a jump start into a fast-growing and well-paying career. The veterans we are working with come with a wealth of life experiences, from strenuous training to long deployments; these skills and grit in conjunction with the training from V School make them prime candidates to be very successful.",
  cite1: "Chris Coghlin, CEO of Field of Lions",
  testimonialImg1: require("../images/chris-testimonial.png"),

  // NewsLetter

  // Real World Needs
  respondingHeader: "Responding To Real World Needs",
  respondingSubheader: "RESPONSIVE LEARNING AT V SCHOOL",
  respondingDescription:
    "Everyone learns in their own way, customized education is necessary for individual success. Responsive learning is designed around your past experiences, current learning needs and your current schedule so you can reskill without giving up your life.",

  // Responsive Industry
  industryHeader: "Responsive To Industry",
  industryInfo:
    "Industry needs change quickly, and we change with them. We build curriculum with tech leaders around the globe to stay in line with what hiring managers are looking for.",
  industryImg: require("../images/industry-img.png"),

  // Quote(s)
  quotes: [
    {
      testimonial:
        "“ I just wanted to take a minute and let you know how well (VS graduate) is doing in just her second week here at MX. Her enthusiasm and work ethic are outstanding as expected...",
      logo: require("../images/mx-logo.png"),
      cite: "Craig Andrew",
      position: "Lead QA Engineer",
      id: "103",
    },
  ],

  // Responsive Ability
  abilityHeader: "Responsive To Ability",
  abilityDescription:
    "Lessons are designed around your past experiences and what you need to accept a job in the tech industry. Knowing that a strong portfolio is vital, we help you work on real-life projects and land internships so you have the experience to accept an industry job.",
  abilityDesktopImg: require("../images/ability-desktopimg.png"),
  abilityMobileImg: require("../images/ability-mobileimg.png"),
  abilityBullets: [
    "Full-Time Instructors And Teachers Assistants",
    "Revist Lessons Anytime You Are Stuck",
    "Pass Modules With Technical Assessments",
    "Industry Leader Guest Speakers And Lessons",
    "Add Real Experience To Your Portfolio",
    "Work With Other Students On Group Projects",
  ],

  // Responsive Life
  lifeHeader: "Responsive To Life",
  lifeDescription:
    "There’s no better time to start a better life than now, and now you can. We combine asynchronous curriculum and immersive learning so you have the flexibility to learn around a busy schedule.",
  lifeTestimonial:
    "It’s amazing having a career that allows me to work from home. Thre has never been a timem where this time of flexibility has been more important.",
  lifeCite: "Ani Turner",
  lifeImg: require("../images/ani-testimonial.png"),
  lifeCourse: "Web Development Alumni",
  lifeBullets: [
    "100% Online Remote Classes",
    "Structured Around Your Life Schedule",
    "Take The Time You Need On Difficult Lessons",
    "Work With Career Mentors From Day One",
    "Daily  Standups With Instructors And Team",
    "Complete The Program By Landing A Job",
  ],

  // CTA
  ctaHeader: "Ready To Make The Leap?",
  ctaSubHeader: "100% ONLINE | GI BILL ACCEPTED | PAY LATER",
  ctaBtnText: "Schedule A Time To Chat",
  ctaLink: "https://calendly.com/v-school/apply",
  ctaCourses: [
    {
      bgImg: require("../images/session-bgimg.png"),
      nextSession: "Next Web Development Session",
      sessionDate: "Weekly",
    },
    {
      bgImg: require("../images/session-bgimg.png"),
      nextSession: "Next Experience Design Session",
      sessionDate: "September 2020",
    },
  ],

  // Footer
  programsHeader: "Programs",
  footerCourses: [
    {
      course: "Full Stack JavaScript",
      startDate: "Weekly",
      link: "https://vschool.io/development",
    },
    {
      course: "Experience Design",
      startDate: "September 2020",
      link: "https://vschool.io/experience-design",
    },
  ],
  footerSections: [
    {
      header: "Community",
      items: [
        {
          link: "/",
          text: "Home",
        },
      ],
    },
    {
      header: "Scholarships",
      items: [
        {
          link: "/my-base-scholarship",
          text: "MyBaseGuide Scholarship",
        },
      ],
    },
  ],
}
