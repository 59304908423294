import React from "react"
import styled from "styled-components"
import Link from "../shared/QueryLink"
import { gray, black } from "@vschool/lotus"
import logo from "../../images/vschool-logo2.png"

const FooterSubLinks = styled.div`
    display: flex;
    align-items: center;
    border-top: 2px solid ${gray.darker};
    padding: 24px 0;
    margin: 0 24px;
    background-color: ${black};
    margin-top: 40px;

    @media (min-width: 600px) {
        margin: 0 32px;
    }

    @media (min-width: 840px) {
        margin: 0 40px;
    }

    @media (min-width: 1200px) {
        width: 1114px;
        margin: 0 auto;
    }
`

const SubContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`

const FooterSubInfo = styled.p`
    display: inline-block;
    color: ${gray.dark};
    font-family: "aktiv-grotesk";
    font-size: 12px;
    font-weight: 400;
    margin: 0 4px;
`

const Logo = styled.img`
    width: 32px;
    display: inline-block;
    margin-right: 8px;
`

export default function FooterSubInformation(props) {
    return (
        <FooterSubLinks>
            <Link to="/">
                <Logo src={logo} />
            </Link>
            <div>
                <SubContainer>
                    <FooterSubInfo>
                        © 2020 V School.
                    </FooterSubInfo>
                    <FooterSubInfo>
                        All rights reserved
                    </FooterSubInfo>

                </SubContainer>
            </div>
        </FooterSubLinks>
    )
}
