import React from "react"
import PropTypes from "prop-types"
import Nav from "./nav/Navbar.js"
import Footer from "./footer/Footer.js"
import styled from "styled-components"
import "./layout.scss"

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  flex-grow: 1;
`

const Layout = ({ children }) => {
  return (
    <>
      <MainContainer>
        <ContentContainer>
          <Nav />
          <main>{children}</main>
          <Footer />
        </ContentContainer>
      </MainContainer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
