import React from "react"
import styled from "styled-components"
import { black, gray, orange } from "@vschool/lotus"
import Link from "../shared/QueryLink"
import SubscribeForm from "./SubscribeForm.js"
import FooterSubInformation from "./FooterSubInformation.js"
import { data } from "../../data/siteData.js"

const Container = styled.footer`
  background-color: ${black};
`

const FooterContainer = styled.div`
  background-color: ${black};
  color: ${gray.base};
  padding: 32px 24px 0 24px;

  @media (min-width: 600px) {
    padding: 32px 0 72px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 620px;
    margin: 0 auto;
  }

  @media (min-width: 840px) {
    padding: 40px 0 72px 0;
  }

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1114px;
    margin: 0 auto;
  }
`

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    justify-self: center;
  }
`

const FormContainer = styled.div`
  @media (min-width: 600px) {
    grid-column: 1 / -1;
  }
`

const Header = styled.h3`
  color: ${gray.dark};
  font-family: "aktiv-grotesk-extended";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.29px;
  line-height: 18px;
  margin-top: 40px;
  margin-bottom: 8px;
`

const CourseContainer = styled.div`
  margin: 8px 0;
`

const CourseTitle = styled.h3`
  height: 18px;
  width: 156px;
  color: ${gray.base};
  font-family: "aktiv-grotesk";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`

const StartDate = styled.p`
  margin-top: 4px;
  height: 18px;
  width: 144px;
  color: ${orange.base};
  font-family: "aktiv-grotesk";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`

const SLink = styled(Link)`
  padding-top: 8px;
  width: 156px;
  color: ${gray.base};
  font-family: "aktiv-grotesk";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: none;

  :active {
    text-decoration: none;
    color: ${gray.base};
  }
  :visited {
    text-decoration: none;
    color: ${gray.base};
  }
`

const CourseLink = styled(Link)`
  text-decoration: none;
`

const { footerCourses, programsHeader, footerSections } = data

const Footer = () => {
  return (
    <Container>
      <FooterContainer>
        <FormContainer>
          <SubscribeForm />
        </FormContainer>
        <FooterSection>
          <Header>{programsHeader}</Header>
          {footerCourses.map(({ course, link, startDate }) => (
            <CourseLink to={link} key={course}>
              <CourseContainer>
                <CourseTitle>{course}</CourseTitle>
                <StartDate>Starts {startDate}</StartDate>
              </CourseContainer>
            </CourseLink>
          ))}
        </FooterSection>
        {footerSections.map(({ header, items }, i) => (
          <FooterSection key={header}>
            <Header>{header}</Header>
            {items.map(({ link, text }) => (
              <SLink to={link} key={link}>
                {text}
              </SLink>
            ))}
          </FooterSection>
        ))}
      </FooterContainer>
      <FooterSubInformation />
    </Container>
  )
}

export default Footer
